(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name cancellations.newCancellation.controller:NewCancellationCtrl
   *
   * @description
   *
   */
  angular
    .module('neo.home.cancellations.new')
    .controller('NewCancellationCtrl', NewCancellationCtrl);

  function NewCancellationCtrl($state, $mdToast, $filter, Cancellations, cancellationTypes, match) {
    var vm = this;
    vm.ctrlName = 'NewCancellationCtrl';

    vm.cancellationTypes = cancellationTypes;

    vm.localTeam = match.localTeam;
    vm.localTeam.columnName = 'CHAMPIONSHIPS.LOCAL_TEAM';

    vm.visitorTeam = match.visitorTeam;
    vm.visitorTeam.columnName = 'CHAMPIONSHIPS.VISITOR_TEAM';

    vm.saveCancellation = saveCancellation;

    function saveCancellation() {
      Cancellations.save({id: match.id}, vm.cancellation, function () {
        $mdToast.show(
          $mdToast.simple()
            .textContent($filter('translate')('CANCELLATIONS.SUCCESS_CREATION'))
            .position('bottom left')
            .hideDelay(3000)
        );
        $state.go('home.cancellations', {}, {reload: true});
      }, function (error) {
        var message;
        if (error.status === 400) {
          message = 'CANCELLATIONS.' + error.data.message;
        } else {
          message = 'SERVER_ERROR';
        }
        $mdToast.show(
          $mdToast.simple()
            .textContent($filter('translate')(message))
            .position('bottom left')
            .hideDelay(3000)
        );
      });
    }

  }
}());
